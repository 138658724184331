.StripeCheckoutForm {
  margin: 4em auto auto 4em;
  justify-content: center;
  align-items: center; }
  .StripeCheckoutForm .message {
    text-align: center; }
  .StripeCheckoutForm .spinner {
    margin: 0 auto;
    color: black;
    height: 10em; }
